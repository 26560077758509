export default {
    tabs: {
        root: {
            borderBottom: '1px solid #999999',
            backgroundColor: '#33afb0'
        },
        color: '#ffffff',
        backgroundColor: '#ffffff',
    },

    tab: {
        root: {
            textTransform: 'none',
            minWidth: '180px',
            fontSize: '14px',
            fontWeight: 500,
            letterSpacing: '0.75px',
            backgroundColor: '#33afb0',
            borderRadius: '4px',
            color: '#533278',
            '&$selected': {
                color: '#533278',
                backgroundColor: '#ffffff',
                borderBottom: '1px solid #f50057',
            },
            '&:focus': {
                color: '#533278',
                outline: 'none'
            },
        },
        selected: {},
    },
}