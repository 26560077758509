import React, { Component } from 'react';
import configurations from "../../../configurations";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress'

class EnrollFrame extends Component{
    constructor(props) {
        super(props);
        this.onMessageReceived = this.onMessageReceived.bind(this);
        this.bindEvents();
        this.state = {
            urlParam:null,
            loaderShow: false
        };
        
    }

    bindEvents() {
        window.addEventListener("message", this.onMessageReceived, false);
      }
    
      onMessageReceived(event) {
        var data = JSON.parse(event.data);
        if(data.message==="GoToDashboard"){
          this.props.history.push("/");
          sessionStorage.removeItem("currentActiveProspect");
        }else{
          this.props.history.push("/");
          sessionStorage.removeItem("currentActiveProspect");
        }
      }
    componentDidMount() {
      const channel = new BroadcastChannel('myChannel');
      channel.postMessage(sessionStorage.getItem("tab"));
        let urlValues = window.location.search ? window.location.search.split('=') : [];
        if (urlValues && urlValues.length > 0) {
          if (urlValues[1]) {
            this.setState({urlParam: urlValues[1]}, () =>{
                this.props.history.push("/Enrollment");
      
            })
          }
        }else{
          let currentActiveProspect = sessionStorage.getItem("currentActiveProspect");
          if(currentActiveProspect){
            let obj= JSON.parse(currentActiveProspect);
            this.launchEnrollmentportal(obj);
          }else{
            this.props.history.push("/");
          }
          }
    }

    launchEnrollmentportal = (obj) =>{
      this.setState({loaderShow: true, })
      axios.post(configurations.baseUrl + '/encrypt/encryptData', obj)
      .then(response => {
          this.setState({urlParam: response.data.response, loaderShow: false, });
      }).catch(error => {
              this.setState({
                  loaderShow: false,
              });
             });
    }
 
    render() {

        return (
          <>
            {this.state.loaderShow ? 
                <Loader/>
              :null}
            <div style={{width:"100vw", height:"100vh"}}>
            
               {this.state.urlParam &&
                <iframe 
                 frameBorder="0" 
                 allowtransparency="true" allowfullscreen="true"
                 src={configurations.enrollmentURL + '/login#state=' + this.state.urlParam} style={{width: "100%", height: "100%"}}/>}
            </div>
            </>
        );
    }
}

export default EnrollFrame;

const Loader = () =>{
  return(  
  <div style={{ width: '100%', height: '100%', position: 'fixed', zIndex: '101', top: "50%", left: "50%", opacity: '0.8' }}>
  <CircularProgress />
</div>)
}